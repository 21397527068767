<template>
    <div class="container-auth">
        <b-card class="p-3 bg-white" style="background: white !important">
            <div class="text-center">
                <img class="w-50" src="@/assets/images/logo-blue.png" alt="">
            </div>
            <div class="text-center mt-4">
                <h3 class="text-center" style="color:#09115C">
                    SignIn
                </h3>
                <h6 class="text-center" style="color:#09115C">
                    Login to stay connected
                </h6>
            </div>
            <AlertErrors />

            <div class="text-center mb-3 mt-3">
                <qrcode-vue :value="passcode" :size="250" level="M" style="margin: auto"/>

                <p style="color:#09115C">
                    Scan this QR in your app to login
                </p>
            </div>
            <div class="row">
                <div class="col-6">
                    <a href="https://apps.apple.com/ec/app/eleven-wallet/id1599917246" target="_blank">
                        <img class="w-100" src="/images/app-store.png" alt="">
                    </a>
                </div>
                <div class="col-6">
                    <a href="https://play.google.com/store/apps/details?id=com.co.eost.elvwallet" target="_blank">
                        <img class="w-100" src="/images/play-store.png" alt="">
                    </a>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-6">
                    <router-link class="btn btn-outline-link w-100" :to="{ name: 'RecoverPassword' }">
                        I forgot my password
                    </router-link>
                </div>
                <div class="col-6">
                    <router-link class="btn btn-primary w-100" :to="{ name: 'Signup' }">
                        SignUp
                    </router-link>
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import QrcodeVue from 'qrcode.vue'
export default {
    components: {
        QrcodeVue
    },
    data () {
        return {
            loading: false,
            validating: false,
            form: {
                code: null,
                type: 'web'
            },
            secureForm: {
                ip: null,
                code: null,
                location: null,
                browser: 'web',
                lat_long: null
            },
            interval: null,
            generateCode: null,
            timeToCheck: null
        }
    },
    created () {
        this.passcode = this.generateString(10)
        this.secureForm.code = this.passcode
        this.form.code = this.passcode
        this.loading = true
        this.getIpLogin().then(() => {
            this.loading = false
        })
        this.startToCheck()
        /* this.startRegenerate() */
    },
    onBeforeUnmount () {
        this.clearInterval(this.interval)
        this.clearInterval(this.generateCode)
    },
    methods: {
        ...mapActions('auth', ['login', 'validateQrCode', 'saveQrCode', 'createSession']),
        startToCheck () {
            this.interval = setInterval(() => {
                if(this.$route.name == 'Signin' ) {
                    this.checkQrCode()
                }
            }, 5000);
        },
        startRegenerate () {
            this.generateCode = setInterval(() => {
                if(this.$route.name == 'Signin' ) {
                    this.loading = true
                    this.passcode = this.generateString(10)
                    this.secureForm.code = this.passcode
                    this.form.code = this.passcode
                    this.loading = false
                }
            }, 60000);
        },
        generateString(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() *  charactersLength));
            }
            
            return result.toUpperCase();
        },
        async getIpLogin() {
            try {
                const geo = await axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=6f1a821a1df54429b487b6ca9fd9249e')
                this.secureForm.ip = geo.data.ip_address
                this.secureForm.location = geo.data.city +', ' + geo.data.region + ', ' + geo.data.country
                this.secureForm.lat_long = 'Lat: ' + geo.data.latitude + ', Long: ' + geo.data.longitude

            } catch (error) {
                console.log("Error to get")
            }
        },
        checkQrCode () {
          if(this.$route.name == 'Signin' ) {
            console.log("CHECKING Raro", this.form.code)
            this.loading = true
            this.validateQrCode(this.form).then(response => {
                this.loading = false
                if(response != 'unregister') {
                    this.storeQrSession()
                    clearInterval(this.interval);
                }
            })
            this.loading = false
          }
        },
        storeQrSession () {
            this.validating = true
            this.saveQrCode(this.secureForm).then(() => {
                axios.defaults.headers.common['DEVICEID'] = this.secureForm.code
                this.createSession(this.form).then(response => {
                    this.validating = false
                    this.$router.push({
                        name: 'Validation'
                    })
                })
            })
        }
    }
}
</script>
<style scoped>
    h3{
        text-align: left;
        font-size: 40px;
        line-height: 48px;
        color: white;
    }
    h6{
        text-align: left;
        color: white;
        font-size: 22px;
        font-weight: 200;
        line-height: 32px;
    }
    .form-input{
        text-align: left;
        color: white;
    }
</style>